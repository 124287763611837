import { useCallback, useRef } from 'react'
import { useChat } from '../contexts/chat/useChat'
import MentionItem from '../components/mention/MentionItem'

export const useMentions = () => {
  const { chatClient } = useChat()
  const debounceTimeout = useRef(null)

  const queryUsers = useCallback(
    async (term) => {
      if (!chatClient || !term) return []

      const filter = {
        $or: [
          { name: { $autocomplete: term } },
          { id: { $autocomplete: term } },
        ],
        role: { $ne: 'admin' },
        id: { $ne: chatClient.userID },
      }

      const sort = { last_active: -1 }
      const options = { limit: 5 }

      try {
        const response = await chatClient.queryUsers(filter, sort, options)
        return response.users || []
      } catch (error) {
        console.error('Error fetching users:', error)
        return []
      }
    },
    [chatClient]
  )

  const debouncedQueryUsers = useCallback(
    (term, callback) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current)
      }

      debounceTimeout.current = setTimeout(async () => {
        const users = await queryUsers(term)
        callback(
          users.map((user) => ({
            id: user.id,
            name: user.name,
            username: user.username,
            profileImage: user?.profile?.image || '',
          }))
        )
      }, 300)
    },
    [queryUsers]
  )

  const mentionsTrigger = {
    '@': {
      dataProvider: (term) =>
        new Promise((resolve) => {
          debouncedQueryUsers(term, resolve)
        }),
      component: MentionItem,
      output: (item) => `@${item.username}`,
    },
  }

  return { mentionsTrigger }
}

export default useMentions
