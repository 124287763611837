import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Feed, useStreamContext } from 'react-activity-feed'
import CreatePostTop from './CreatePostTop'
import MainHeader from '../header/MainHeader'
import Timeline from './Timeline'
import LoadingIndicator from '../loading/LoadingIndicator'
import GlobalFeed from '../global/GlobalFeed'

const Container = styled.div`
  .header {
    top: 0;
    z-index: 1;
  }

  .create-post-top {
    border-bottom: 1px solid #333;
  }

  .new-posts-info {
    border-bottom: 1px solid #333;
    padding: 20px;
    text-align: center;
    color: var(--theme-color);
    display: block;
    width: 100%;
    font-size: 16px;

    &:hover {
      background: #111;
    }
  }
`

const HomeContent = () => {
  const [viewGlobalFeed, setViewGlobalFeed] = useState(false)
  const { client } = useStreamContext()
  const user = client.currentUser.data

  useEffect(() => {
    const checkTimelineFeed = async () => {
      try {
        const timelineFeed = client.feed('timeline', client.userId)
        const response = await timelineFeed.get({ limit: 1 })
        if (response.results.length === 0) {
          setViewGlobalFeed(true)
        }
      } catch (error) {
        console.error('Error fetching timeline feed:', error)
      }
    }

    if (client.userId) {
      checkTimelineFeed()
    }
  }, [client])

  if (!user)
    return (
      <Container>
        <LoadingIndicator />
      </Container>
    )

  return (
    <Container>
      <div className="header">
        <MainHeader
          viewGlobalFeed={viewGlobalFeed}
          setViewGlobalFeed={setViewGlobalFeed}
        />
      </div>
      <Feed feedGroup="user">
        <div className="create-post-top">
          <CreatePostTop />
        </div>
        {viewGlobalFeed ? null : <Timeline />}
      </Feed>
      {viewGlobalFeed ? <GlobalFeed /> : null}
    </Container>
  )
}

export default HomeContent
