import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import Collapse from '../Icons/Collapse'
import Expand from '../Icons/Expand'

const StatsContainer = styled.div`
  .flight-stats {
    display: grid;
    grid-template-columns: ${(props) =>
      props.expanded
        ? 'repeat(auto-fit, minmax(120px, 1fr))'
        : 'repeat(5, 1fr)'};
    gap: 10px;
    color: #fff;
    margin-bottom: 10px;
    transition: max-height 0.5s ease 0.05s;
    max-height: ${(props) => (props.expanded ? '400px' : '60px')};
    align-items: end;
    position: relative;
    width: calc(100% - 12px);

    ${(props) => css`
      @media (max-width: 580px) {
        width: ${props.expanded ? 'calc(100% + -16px)' : 'calc(100% + 30px)'};
        margin-left: ${props.expanded ? '0' : '-46px'};
      }
    `}

    .stat {
      display: flex;
      flex-direction: column;
      height: fit-content;

      &-value {
        font-weight: bold;
        font-size: 16px;
      }

      &-label {
        font-size: 12px;
        color: #999;
      }

      .units {
        font-weight: 200;
      }
    }

    .expand-placeholder {
      visibility: hidden;
      width: 46px;
      height: 4px;
    }

    .expand-stats {
      position: absolute;
      bottom: -10px;
      right: -30px;
      color: var(--theme-color);
      cursor: pointer;
      padding: 0;
      margin-bottom: 10px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      z-index: 2;

      &:hover {
        background-color: #333;
      }
    }
  }
`

const FlightStats = ({ igc, isThread = false }) => {
  const [expanded, setExpanded] = useState(isThread)

  if (!igc?.data) return null

  const handleToggleExpand = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setExpanded(!expanded)
  }

  function wrapUnits(duration) {
    return duration.split(/([hm])/).map((part, index) => {
      if (part === 'h' || part === 'm') {
        return (
          <small key={index} className="units">
            {part}
          </small>
        )
      }
      return part
    })
  }
  const wrappedRouteDuration = wrapUnits(igc.data.routeDuration)
  const wrappedFlightDuration = wrapUnits(igc.data.flightDuration)

  let classification = igc.data.classification
  classification =
    classification.charAt(0) + classification.slice(1).toLowerCase()
  classification = classification.replace(/fai/gi, 'FAI')

  return (
    <StatsContainer expanded={expanded}>
      <div className="flight-stats" onClick={(e) => e.stopPropagation()}>
        <div className="stat">
          <span className="stat-label">Score</span>
          <span className="stat-value">{Math.trunc(igc.data.score)}</span>
        </div>
        <div className="stat">
          <span className="stat-label">Route distance</span>
          <span className="stat-value">
            {Math.floor(igc.data.routeDistance)}
            <small className="units">km</small>
          </span>
        </div>
        <div className="stat">
          <span className="stat-label">Route time</span>
          <span className="stat-value">{wrappedRouteDuration}</span>
        </div>
        <div className="stat">
          <span className="stat-label">Route avg</span>
          <span className="stat-value">
            {Math.floor(igc.data.avgRouteSpeed)}
            <small className="units">kmh</small>
          </span>
        </div>
        <div className="stat">
          <span className="stat-label">Type</span>
          <span style={{ fontSize: '12px' }} className="stat-value">
            {classification}
          </span>
        </div>
        {expanded && (
          <>
            <div className="stat">
              <span className="stat-label">Free distance</span>
              <span className="stat-value">
                {Math.floor(igc.data.freeDistance)}
                <small className="units">km</small>
              </span>
            </div>
            <div className="stat">
              <span className="stat-label">Flight time</span>
              <span className="stat-value">{wrappedFlightDuration}</span>
            </div>
            <div className="stat">
              <span className="stat-label">Flight avg</span>
              <span className="stat-value">
                {Math.floor(igc.data.freeDistanceAvgSpeed)}
                <small className="units">kmh</small>
              </span>
            </div>
            <div className="stat">
              <span className="stat-label">Max climb</span>
              <span className="stat-value">
                {igc.data.maxClimb || 0}
                <small className="units">m/s</small>
              </span>
            </div>
            <div className="stat">
              <span className="stat-label">Max sink</span>
              <span className="stat-value">
                {igc.data.maxSink}
                <small className="units">m/s</small>
              </span>
            </div>
            <div className="stat">
              <span className="stat-label">Max alt</span>
              <span className="stat-value">
                {igc.data.maxAltitude}
                <small className="units">m</small>
              </span>
            </div>
            <div className="stat">
              <span className="stat-label">Max speed</span>
              <span className="stat-value">
                {Math.floor(igc.data.maxSpeed)}
                <small className="units">kmh</small>
              </span>
            </div>
            <div className="stat">
              <span className="stat-label">Date</span>
              <span style={{ fontSize: '14px' }} className="stat-value">
                {igc.data.date}
              </span>
            </div>
            <div className="stat">
              <span className="stat-label">Site</span>
              <span style={{ fontSize: '12px' }} className="stat-value">
                {igc.data.site || '?'}
              </span>
            </div>
            {igc.data.gliderType && (
              <div className="stat">
                <span className="stat-label">Wing</span>
                <span style={{ fontSize: '12px' }} className="stat-value">
                  {igc.data.gliderType}
                </span>
              </div>
            )}
            <div className="stat">
              <span className="stat-label">Tracklog length</span>
              <span className="stat-value">
                {Math.floor(igc.data.totalDistance)}
                <small className="units">km</small>
              </span>
            </div>
          </>
        )}
        {!isThread && (
          <button className="expand-stats" onClick={handleToggleExpand}>
            {expanded ? <Collapse /> : <Expand />}
          </button>
        )}
      </div>
    </StatsContainer>
  )
}

export default FlightStats
