import React, { useState, useCallback } from 'react'
import classNames from 'classnames'
import { Lightbox } from './Lightbox'

export const Gallery = React.memo(({ images = [] }) => {
  const [index, setIndex] = useState(null)
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)

  const handleNext = useCallback(() => {
    if (index < images.length - 1) {
      setIndex(index + 1)
    }
  }, [index, images.length])

  const handlePrev = useCallback(() => {
    if (index > 0) {
      setIndex(index - 1)
    }
  }, [index])

  const handleImageClick = useCallback((i) => {
    setIndex(i)
    setIsLightboxOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsLightboxOpen(false)
    setIndex(null)
  }, [])

  return (
    <div className={classNames('raf-gallery')}>
      {images.slice(0, 5).map((image, i) => (
        <div
          role="button"
          className={classNames('img', {
            'img--last': i === 4 && images.length > 5,
          })}
          onClick={() => handleImageClick(i)}
          key={`image-${i}`}
        >
          <img src={image} className="raf-gallery__image" alt="" />
          {i === 4 && images.length > 5 && <p>{images.length - 4} more</p>}
        </div>
      ))}

      {isLightboxOpen && index !== null && (
        <Lightbox
          images={images}
          currentIndex={index}
          onCloseRequest={handleClose}
          onMoveNextRequest={handleNext}
          onMovePrevRequest={handlePrev}
        />
      )}
    </div>
  )
})
