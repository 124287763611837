import { useFeedContext, useStreamContext } from 'react-activity-feed'

import { getIdByUsername } from '../contexts/auth/AuthSlice'
import extractFirstMentionFromText from '../utils/mention'
import useMentionNotification from './useMentionNotification'
import useNotification from './useNotification'

export default function useComment() {
  const feed = useFeedContext()
  const { createNotification } = useNotification()
  const { client, user } = useStreamContext()
  const { createMentionNotification } = useMentionNotification()

  const createComment = async (text, activity) => {
    try {
      const actor = activity.actor

      await feed.onAddReaction('comment', activity, {
        text,
        data: {
          ...client.currentUser.data,
          parentActivityId: activity.id,
        },
      })

      const mentionedUsername = extractFirstMentionFromText(text)

      if (actor.id !== user.id) {
        createNotification(
          actor.id,
          'comment',
          {
            text,
            data: {
              ...client.currentUser.data,
              parentActivityId: activity.id,
            },
          },
          `SO:post:${activity.object.id}`
        )
      }

      if (mentionedUsername) {
        try {
          const mentionedUserId = await getIdByUsername(mentionedUsername)

          if (mentionedUserId !== actor.id) {
            await createMentionNotification({
              mentionedUserId,
              activity,
              text,
              postId: activity.object.id,
              mentionType: 'comment',
            })
          }
        } catch (error) {
          console.error(
            `Failed to get user ID for ${mentionedUsername}:`,
            error
          )
        }
      }
    } catch (error) {
      console.error('Error posting comment:', error)
    }
  }

  return {
    createComment,
  }
}
