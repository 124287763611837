import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { API_ENDPOINT } from '../../config'

const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
`

const ModalContent = styled.div`
  width: 400px;

  background-color: black;
  border: 1px solid rgb(51, 51, 51);
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  color: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
`

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`

const Input = styled.input`
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  border: 1px solid rgb(51, 51, 51);
  background-color: black;
  color: white;
  transition: outline 0.3s;
  font-size: 1rem;

  &:focus {
    outline: 4px solid var(--theme-color);
  }
`

const Button = styled.button`
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #555;
    cursor: not-allowed;
  }
`

const CancelButton = styled.button`
  width: 100%;
  padding: 1rem;
  margin-top: 10px;
  color: #007bff;
  background-color: black;
  border: 1px solid rgb(51, 51, 51);
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    background-color: rgba(26, 155, 240, 0.1);
  }
`

const ErrorMessage = styled.div`
  color: #ff0000;
  margin-bottom: 1rem;
`

const SuccessMessage = styled.div`
  color: var(--theme-color);
  margin-bottom: 1rem;
`

const PasswordResetModal = ({ onClose }) => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const navigate = useNavigate()

  const handlePasswordReset = async () => {
    setIsLoading(true)
    setMessage('')
    setError('')

    try {
      const response = await fetch(`${API_ENDPOINT}/auth/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      })

      if (!response.ok) {
        const data = await response.json()
        throw new Error(data.message || 'Failed to send password reset email')
      }

      setMessage(
        'Password reset email sent successfully. Redirecting in 3 seconds...'
      )
      setSuccess(true)
      setTimeout(() => navigate('/login'), 3000)
    } catch (error) {
      setError(error.message)
      setIsLoading(false)
    }
  }

  return (
    <ModalContainer>
      <ModalContent>
        <Title>Reset Password</Title>
        <form onSubmit={handlePasswordReset}>
          <Input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
            autoComplete="email"
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
          {message && <SuccessMessage>{message}</SuccessMessage>}
          <Button onClick={handlePasswordReset} disabled={isLoading || success}>
            {isLoading ? 'Sending...' : 'Send Reset Email'}
          </Button>
        </form>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
      </ModalContent>
    </ModalContainer>
  )
}

export default PasswordResetModal
