import React from 'react'
import styled from 'styled-components'
import UserImage from '../profile/UserImage'

const StyledMentionItem = styled.div`
  display: flex;
  align-items: center;
  min-width: 200px;

  .user {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }

  .suggestion-text {
    display: flex;
    flex-direction: column;
  }

  .suggestion-name {
    font-weight: bold;
    color: #fff;
    font-size: 20px;
    height: 20px;
  }

  .suggestion-username {
    color: #aaa;
    font-size: 16px;
    font-weight: bold;
  }
`

const MentionItem = ({ entity }) => (
  <StyledMentionItem>
    <div className="user">
      <UserImage
        src={entity.profileImage}
        alt={entity.name}
        clickable={false}
      />
    </div>
    <div className="suggestion-text">
      <div className="suggestion-name">{entity.name}</div>
      <div className="suggestion-username">@{entity.username}</div>
    </div>
  </StyledMentionItem>
)

export default MentionItem
