import { useState, useRef, useCallback } from 'react'
import { Activity, useStreamContext } from 'react-activity-feed'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import useComment from '../../hooks/useComment'
import useOutsideClick from '../../hooks/useOutsideClick'
import { generatePostLink } from '../../utils/links'
import { formatStringWithLink } from '../../utils/string'
import More from '../Icons/More'
import Expand from '../Icons/Expand'
import Collapse from '../Icons/Collapse'
import UserImage from '../profile/UserImage'
import CommentDialog from './CommentDialog'
import { CommentSection } from './CommentSection'
import { Gallery } from './Gallery'
import PostActorName from './PostActorName'
import LeafletMap from './LeafletMap'
import PostMoreMenu from './PostMoreMenu'
import PostActions from './PostActions'

const Block = styled.div`
  display: flex;
  border-bottom: 1px solid #333;
  padding: 10px;
  cursor: pointer;
  position: relative;

  &:hover {
    background-color: rgb(17, 17, 17);
  }

  .user-image {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px;

    img,
    svg {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }

  .post {
    cursor: pointer;
    flex: 1;

    @media (max-width: 400px) {
      width: 232px;
    }

    .link {
      display: block;
      padding-bottom: 5px;
      text-decoration: none;
      width: 100%;
    }

    &__text {
      color: white;
      font-size: 15px;
      line-height: 20px;
      margin-top: 3px;
      margin-bottom: 10px;
      width: calc(100% + 32px);
      word-break: break-word;
      position: relative;

      &--link {
        color: var(--theme-color);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__image {
      overflow: hidden;
      width: calc(100% + 32px);
      border-radius: 10px;
    }

    &__igc {
      width: calc(100% + 32px);
    }
  }

  .expand-text {
    color: var(--theme-color);
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: absolute;
    right: -18px;
    bottom: -4px;

    &:hover {
      background-color: #333;
    }
  }

  .more {
    min-width: 40px;
    height: 40px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin-top: -10px;

    &:hover {
      background-color: rgb(51, 51, 51);
    }
  }

  .comment-item {
    border-radius: 5px;
    background-color: #333;
  }

  .like-button {
    padding: 0 10px 2px;

    svg {
      height: 14px;
    }
  }

  .raf-comment-item {
    border: none;
    padding: 0;
  }

  .raf-reaction-icon__label {
    color: #7a8287;
  }

  .raf-activity__mention:hover {
    text-decoration: underline;
  }
`

export default function PostBlock({ activity }) {
  const { user } = useStreamContext()
  const navigate = useNavigate()
  const [commentDialogOpened, setCommentDialogOpened] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [menuOpened, setMenuOpened] = useState(false)
  const menuRef = useRef(null)
  const moreIconRef = useRef(null)

  const { createComment } = useComment()

  useOutsideClick([menuRef, moreIconRef], () => {
    if (menuOpened) setMenuOpened(false)
  })

  const actor = activity.actor || { data: {} }
  const actorName = actor.data?.name || 'Unknown'
  const actorUsername = actor.data?.username || 'Unknown'
  const actorImage = actor.data?.profile?.image || ''

  let hasLikedPost = false
  const post = activity.object.data

  if (activity?.own_reactions?.like) {
    const myReaction = activity.own_reactions.like.find(
      (l) => l.user.id === user.id
    )
    hasLikedPost = Boolean(myReaction)
  }

  const postLink = activity.id
    ? generatePostLink(actorUsername, activity.id)
    : '#'

  const onPostComment = async (text) => {
    await createComment(text, activity)
  }

  const og = activity.attachments?.og || null
  const images = activity.attachments?.images || []
  const igc = activity.attachments?.igc || null
  const wholeSite = igc && igc[0]?.data?.site
  const site = wholeSite ? wholeSite.split('-')[0] : null

  const handleMouseDown = useCallback((e) => {
    if (e.target.closest('.comment-item')) return
    setIsMouseDown(true)
  }, [])

  const handleMouseMove = useCallback(
    (e) => {
      if (
        isMouseDown &&
        (Math.abs(e.movementX) > 0.5 || Math.abs(e.movementY) > 0.5)
      ) {
        setIsDragging(true)
      }
    },
    [isMouseDown]
  )

  const handleMouseUp = useCallback(
    (e) => {
      if (!isDragging && !isNonNavigableClick(e.target)) {
        navigate(postLink)
      }
      setIsMouseDown(false)
      setIsDragging(false)
    },
    [isDragging, navigate, postLink]
  )

  const isNonNavigableClick = (target) => {
    const nonNavigableClasses = [
      'expand-stats',
      'expand-text',
      'expand-og-text',
      'comment-icon',
      'heart-icon',
      'more-icon',
      'menu-item',
      'user-image',
      'user--name',
      'post__image',
      'ReactModalPortal',
      'raf-activity__link',
      'raf-audio__image',
      'raf-video__frame',
      'load-more-paginator',
      'comment-item',
      'raf-activity__mention',
      'post__text--link',
    ]

    const hasNonNavigableClass = (element) =>
      nonNavigableClasses.some((className) =>
        element.classList.contains(className)
      )

    let currentElement = target
    while (currentElement) {
      if (currentElement.classList && hasNonNavigableClass(currentElement)) {
        return true
      }
      currentElement = currentElement.parentElement
    }
    return false
  }

  const toggleTextExpansion = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setIsExpanded(!isExpanded)
  }

  const renderText = () => {
    const text = post?.text || ''
    if (text.length > 350) {
      return (
        <>
          <p className="post__text">
            {formatStringWithLink(
              isExpanded ? text : text.slice(0, 350),
              'post__text--link',
              false,
              handleMentionClick
            )}{' '}
            {!isExpanded && '...'}
            <span onClick={toggleTextExpansion} className="expand-text">
              {!isExpanded ? <Expand /> : <Collapse />}
            </span>
          </p>
        </>
      )
    } else {
      return (
        <p className="post__text">
          {formatStringWithLink(
            text,
            'post__text--link',
            false,
            handleMentionClick
          )}
        </p>
      )
    }
  }

  const handleMoreIconClick = (e) => {
    e.stopPropagation()
    setMenuOpened((prev) => !prev)
  }

  const handleMentionClick = useCallback(
    (mention) => {
      navigate(`/${mention}`)
    },
    [navigate]
  )

  return (
    <>
      <Block
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        role="button"
      >
        <div className="user-image" onClick={(e) => e.stopPropagation()}>
          <UserImage
            src={actorImage}
            alt={actorName}
            username={actorUsername}
          />
        </div>
        <div className="post">
          <PostActorName
            name={actorName}
            username={actorUsername}
            time={activity.time}
            site={site}
          />
          <div className="post__details">
            {og ? (
              <Activity
                activity={activity}
                onClickMention={handleMentionClick}
              />
            ) : (
              renderText()
            )}
            {igc && igc.length > 0 && (
              <div className="post__igc" onClick={(e) => e.stopPropagation()}>
                <LeafletMap igc={igc[0]} />
              </div>
            )}
            {images.length > 0 && (
              <div className="post__image" onClick={(e) => e.stopPropagation()}>
                <Gallery images={images} />
              </div>
            )}
          </div>

          <PostActions
            activity={activity}
            hasLikedPost={hasLikedPost}
            setCommentDialogOpened={setCommentDialogOpened}
          />
          <CommentSection
            activityId={activity.id}
            reactionCounts={activity.reaction_counts}
            objectId={activity.object.id}
          />
        </div>
        <button
          className="more more-icon"
          onClick={handleMoreIconClick}
          ref={moreIconRef}
        >
          <More color="#777" size={20} />
        </button>
        {menuOpened && (
          <PostMoreMenu
            activity={activity}
            onClose={() => setMenuOpened(false)}
            ref={menuRef}
          />
        )}
      </Block>
      {activity.id && commentDialogOpened && (
        <CommentDialog
          onPostComment={onPostComment}
          shouldOpen={commentDialogOpened}
          onClickOutside={() => setCommentDialogOpened(false)}
          activity={activity}
        />
      )}
    </>
  )
}
