import React, { useState, useRef, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import useOutsideClick from '../../hooks/useOutsideClick'
import More from '../Icons/More'
import { LikeButton } from './LikeButton'
import PostMoreMenu from './PostMoreMenu'
import { CommentItem } from 'react-activity-feed'

const CommentItemContainer = styled.div`
  border-radius: 5px;
  background-color: #333;
  margin-bottom: 6px;
  position: relative;
  width: calc(100% + 32px);

  .comment-more {
    width: 40px;
    height: 40px;
    display: flex;
    opacity: 0.6;
    border-radius: 50%;
    position: absolute;
    right: 2px;
    top: 2px;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: black;
    }
  }

  .raf-comment-item__mention {
    color: var(--theme-color);

    &:hover {
      text-decoration: underline;
    }
  }
`

const LikeButtonContainer = styled.div`
  padding: 0 10px 2px;
  svg {
    height: 14px;
  }
`

const CommentListItem = ({ comment, activityId, objectId }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const navigate = useNavigate()
  const menuRef = useRef()
  const moreIconRef = useRef()

  useOutsideClick([menuRef, moreIconRef], () => {
    setMenuOpen(false)
  })

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const closeMenu = () => {
    setMenuOpen(false)
  }

  const handleMentionClick = useCallback(
    (mention) => {
      navigate(`/${mention}`)
    },
    [navigate]
  )

  const handleUserClick = useCallback(
    (user) => {
      navigate(`/${user.data.username}`)
    },
    [navigate]
  )

  return (
    <CommentItemContainer className="comment-item">
      <button className="comment-more" onClick={toggleMenu} ref={moreIconRef}>
        <More size={18} color="white" />
      </button>
      {menuOpen && (
        <PostMoreMenu
          activity={comment}
          onClose={closeMenu}
          isComment
          ref={menuRef}
        />
      )}
      <CommentItem
        comment={comment}
        onClickUser={handleUserClick}
        onClickMention={handleMentionClick}
      />

      <LikeButtonContainer className="like-button">
        <LikeButton
          reaction={comment}
          activityId={activityId}
          objectId={objectId}
        />
      </LikeButtonContainer>
    </CommentItemContainer>
  )
}

export default CommentListItem
