import { useState, useRef, useCallback } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Activity, useFeedContext, useStreamContext } from 'react-activity-feed'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import useComment from '../../hooks/useComment'
import useLike from '../../hooks/useLike'
import useOutsideClick from '../../hooks/useOutsideClick'
import { formatStringWithLink } from '../../utils/string'
import Comment from '../Icons/Comment'
import Heart from '../Icons/Heart'
import More from '../Icons/More'
import UserImage from '../profile/UserImage'
import CommentDialog from '../post/CommentDialog'
import ThreadForm from './ThreadForm'
import { Gallery } from '../post/Gallery'
import LeafletMap from '../post/LeafletMap'
import PostMoreMenu from '../post/PostMoreMenu'
import PostCommentBlock from './PostCommentBlock'

const Container = styled.div`
  padding: 10px 15px;

  .user {
    display: flex;
    text-decoration: none;

    &__image {
      min-width: 40px;
      max-width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 15px;

      img {
        width: 40px;
        height: 40px;
      }
    }

    &__name {
      &--name {
        color: white;
        font-weight: bold;
      }
      &--id {
        color: #52575b;
        font-size: 14px;
      }
    }

    &__option {
      margin-left: auto;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      &:hover {
        background-color: #333;
      }
    }
  }

  .post {
    margin-top: 20px;

    .raf-activity {
      width: 100%;
    }

    a {
      text-decoration: none;
      color: var(--theme-color);
    }

    &__text {
      color: white;
      font-size: 20px;
      margin-bottom: 10px;
      word-break: break-word;

      &--link {
        color: var(--theme-color);
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    &__time,
    &__reactions,
    &__reactors {
      height: 50px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #555;
      font-size: 15px;
      color: #888;
    }

    &__time {
      &--date {
        margin-left: 12px;
        position: relative;

        &::after {
          position: absolute;
          content: '';
          width: 2px;
          height: 2px;
          background-color: #777;
          border-radius: 50%;
          top: 0;
          bottom: 0;
          left: -7px;
          margin: auto 0;
        }
      }
    }

    &__reactions {
      &__likes {
        display: flex;

        .reaction-count {
          color: white;
          font-weight: bold;
        }

        .reaction-label {
          margin-left: 4px;
        }
      }
    }

    &__reactors {
      justify-content: flex-start;
    }
  }

  .write-reply {
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #555;
  }

  .raf-activity__link:hover {
    text-decoration: underline;
  }

  .raf-activity__mention:hover {
    text-decoration: underline;
  }
`

dayjs.extend(utc)

export default function PostContent({ activity }) {
  const [menuOpened, setMenuOpened] = useState(false)
  const feed = useFeedContext()
  const { client } = useStreamContext()
  const navigate = useNavigate()
  const menuRef = useRef(null)
  const moreIconRef = useRef(null)

  const { createComment } = useComment()
  const { toggleLike } = useLike()

  useOutsideClick([menuRef, moreIconRef], () => setMenuOpened(false))

  const localTime = dayjs.utc(activity.time).local()
  const time = localTime.format('h:mm A')
  const date = localTime.format('MMMM D, YYYY')

  const post = activity.object.data
  const postActor = activity.actor.data

  const [commentDialogOpened, setCommentDialogOpened] = useState(false)

  let hasLikedPost = false

  if (activity?.own_reactions?.like) {
    const myReaction = activity.own_reactions.like.find(
      (l) => l.user.id === client.userId
    )
    hasLikedPost = Boolean(myReaction)
  }

  const onToggleLike = async () => {
    await toggleLike(activity, hasLikedPost)
    feed.refresh()
  }

  const reactors = [
    {
      id: 'heart',
      Icon: Heart,
      onClick: onToggleLike,
    },
    {
      id: 'comment',
      Icon: Comment,
      onClick: () => setCommentDialogOpened(true),
    },
  ]

  const onPostComment = async (text) => {
    await createComment(text, activity)

    feed.refresh()
  }

  const handleMentionClick = useCallback(
    (mention) => {
      navigate(`/${mention}`)
    },
    [navigate]
  )

  const og = activity.attachments?.og || null
  const images = activity.attachments?.images || []
  const igc = activity.attachments?.igc || null

  return (
    <>
      {commentDialogOpened && (
        <CommentDialog
          activity={activity}
          onPostComment={onPostComment}
          onClickOutside={() => setCommentDialogOpened(false)}
        />
      )}
      <Container>
        <Link className="user">
          <div className="user__image">
            <UserImage
              src={postActor?.profile?.image}
              alt={postActor.name}
              username={postActor.username}
            />
          </div>
          <div className="user__name">
            <span className="user__name--name">{postActor.name}</span>
            <span className="user__name--id">@{postActor.username}</span>
          </div>
          <div className="user__option">
            <button
              className="more more-icon"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setMenuOpened(!menuOpened)
              }}
              ref={moreIconRef}
            >
              <More color="#777" size={20} />
            </button>
            {menuOpened && (
              <PostMoreMenu
                activity={activity}
                onClose={() => setMenuOpened(false)}
                ref={menuRef}
                isThread
              />
            )}
          </div>
        </Link>
        <div className="post">
          {og ? (
            <Activity activity={activity} onClickMention={handleMentionClick} />
          ) : (
            <p className="post__text">
              {formatStringWithLink(
                post.text,
                'post__text--link',
                false,
                handleMentionClick
              )}
            </p>
          )}
          {igc && igc.length > 0 && (
            <div className="post__igc" onClick={(e) => e.stopPropagation()}>
              <LeafletMap igc={igc[0]} isThread />
            </div>
          )}
          {images.length > 0 && (
            <div className="post__image">
              <Gallery images={images} />
            </div>
          )}
          <div className="post__time">
            <span className="post__time--time">{time}</span>
            <span className="post__time--date">{date}</span>
          </div>

          <div className="post__reactions">
            <div className="post__reactions__likes">
              <span className="reaction-count">
                {activity.reaction_counts.like || '0'}
              </span>
              <span className="reaction-label">Likes</span>
            </div>
          </div>

          <div className="post__reactors">
            {reactors.map((action, i) => (
              <button onClick={action.onClick} key={`reactor-${i}`}>
                <action.Icon
                  color={
                    action.id === 'heart' && hasLikedPost
                      ? 'var(--theme-color)'
                      : '#888'
                  }
                  fill={action.id === 'heart' && hasLikedPost && true}
                  size={20}
                />
              </button>
            ))}
          </div>
        </div>

        <div className="write-reply">
          <ThreadForm
            onSubmit={onPostComment}
            submitText="Reply"
            collapsedOnMount={true}
            placeholder="Post your reply"
            replyingTo={postActor.username}
          />
        </div>
        {activity.latest_reactions?.comment?.map((comment) => (
          <PostCommentBlock
            key={comment.id}
            comment={comment}
            activityId={activity.id}
            objectId={activity.object.id}
          />
        ))}
      </Container>
    </>
  )
}
