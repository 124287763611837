import styled from 'styled-components'

const Header = styled.header`
  display: flex;
  width: 100%;
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.5);
`

const TabButton = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  color: ${(props) => (props.active ? 'white' : '#666')};
  cursor: pointer;
  position: relative;
  padding: 15px 0;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: ${(props) => (props.active ? '50%' : '0')};
    height: 3px;
    background-color: var(--theme-color);
    border-radius: 5px;
    transition: width 0.3s;
  }
`

export default function MainHeader({ viewGlobalFeed, setViewGlobalFeed }) {
  return (
    <Header>
      <TabButton
        active={!viewGlobalFeed}
        onClick={() => setViewGlobalFeed(false)}
      >
        Following
      </TabButton>
      <TabButton
        active={viewGlobalFeed}
        onClick={() => setViewGlobalFeed(true)}
      >
        Explore
      </TabButton>
    </Header>
  )
}
