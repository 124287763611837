import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { API_ENDPOINT } from '../../config'

const PageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: black;
`

const Container = styled.div`
  max-width: 400px;
  padding: 40px;
  background-color: black;
  border: 1px solid rgb(51, 51, 51);
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  color: white;
`

const Title = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`

const Input = styled.input`
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  border: 1px solid rgb(51, 51, 51);
  background-color: black;
  color: white;
  transition: border-color 0.3s, outline 0.3s;
  font-size: 1rem;

  &:focus {
    border-color: var(--theme-color);
    outline: none;
  }
`

const Button = styled.button`
  width: 100%;
  padding: 1rem;
  margin-top: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #555;
    cursor: not-allowed;
  }
`

const CancelButton = styled.button`
  width: 100%;
  padding: 1rem;
  margin-top: 10px;
  color: #007bff;
  background-color: black;
  border: 1px solid rgb(51, 51, 51);
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    background-color: rgba(26, 155, 240, 0.1);
  }
`

const ErrorMessage = styled.div`
  color: #ff0000;
  margin-bottom: 1rem;
`

const SuccessMessage = styled.div`
  color: var(--theme-color);
  margin-bottom: 1rem;
`

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get('token')

  const handleBlur = () => {
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match')
    } else {
      setError('')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match')
      setSuccess('')
      setLoading(false)
      return
    }

    setLoading(true)
    setError('')
    setSuccess('')

    try {
      const response = await fetch(`${API_ENDPOINT}/auth/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, newPassword }),
      })

      if (!response.ok) {
        const data = await response.json()
        setLoading(false)
        throw new Error(data.message || 'Failed to reset password')
      }

      setSuccess('Password reset successfully. Redirecting in 3 seconds...')
      setTimeout(() => navigate('/login'), 3000)
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  return (
    <PageWrapper>
      <Container>
        <Title>Reset Password</Title>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {success && <SuccessMessage>{success}</SuccessMessage>}
        <form onSubmit={handleSubmit}>
          <Input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Confirm New Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            onBlur={handleBlur}
          />
          <Button type="submit" disabled={loading}>
            {loading ? 'Resetting...' : 'Reset Password'}
          </Button>
          <CancelButton type="button" onClick={() => navigate('/')}>
            Cancel
          </CancelButton>
        </form>
      </Container>
    </PageWrapper>
  )
}

export default ResetPassword
