import { nanoid } from 'nanoid'
import { useStreamContext } from 'react-activity-feed'

import { getIdByUsername } from '../contexts/auth/AuthSlice'
import extractFirstMentionFromText from '../utils/mention'
import useMentionNotification from './useMentionNotification'

export default function usePost() {
  const { client } = useStreamContext()
  const { createMentionNotification } = useMentionNotification()

  const createPost = async (activity) => {
    try {
      const collection = await client.collections.add('post', nanoid(), {
        text: activity.text,
      })

      const enrichedActivity = {
        ...activity,
        object: `SO:post:${collection.id}`,
        to: ['allusers:allusers'],
      }

      const addedActivity = await client
        .feed(activity.feedGroup || 'user', client.userId)
        .addActivity(enrichedActivity)

      const mentionedUsername = extractFirstMentionFromText(activity.text)

      if (mentionedUsername) {
        try {
          const mentionedUserId = await getIdByUsername(mentionedUsername)

          await createMentionNotification({
            mentionedUserId,
            activity: addedActivity,
            text: activity.text,
            postId: collection.id,
            mentionType: 'post',
          })
        } catch (error) {
          console.error(
            `Failed to get user ID for ${mentionedUsername}:`,
            error
          )
        }
      }

      return addedActivity
    } catch (error) {
      console.error('Error creating post:', error)
      throw error
    }
  }

  return {
    createPost,
  }
}
