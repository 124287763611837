import React from 'react'
import styled from 'styled-components'

const LoadMoreButton = styled.button`
  padding: 0 20px;
  margin-left: -10px;
  cursor: pointer;
  background-color: transparent;
  color: #777;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    color: white;
  }
`

export const LoadMoreComments = ({
  hasNextPage,
  loadNextPage,
  refreshing,
  children,
}) => {
  return (
    <div>
      {children}
      {hasNextPage && !refreshing && (
        <LoadMoreButton className="load-more-paginator" onClick={loadNextPage}>
          View comments
        </LoadMoreButton>
      )}
      {refreshing && <div style={{ color: '#777' }}>Loading...</div>}
    </div>
  )
}
