import { createContext, useReducer, useEffect } from 'react'
import { initialState, authReducer } from './AuthSlice'
import { checkUserExists } from './AuthSlice'

export const AuthContext = createContext({
  authState: initialState,
  dispatch: () => null,
})

export const AuthProvider = ({ children }) => {
  const [authState, dispatch] = useReducer(authReducer, initialState, () => {
    const storedUser = localStorage.getItem('authUser')
    const user = storedUser ? JSON.parse(storedUser) : null

    if (user) {
      checkUserExists(user?.user?.id).then((existingUser) => {
        if (!existingUser) {
          localStorage.removeItem('authUser')
          dispatch({ type: 'SET_USER', payload: null })
        }
      })
    }

    return {
      ...initialState,
      authUser: user,
      isAuthenticated: !!user,
    }
  })

  useEffect(() => {
    if (authState.authUser) {
      localStorage.setItem('authUser', JSON.stringify(authState.authUser))
    } else {
      localStorage.removeItem('authUser')
    }
  }, [authState.authUser])

  return (
    <AuthContext.Provider value={{ authState, dispatch }}>
      {children}
    </AuthContext.Provider>
  )
}
