import React, { useState, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { useAuth } from '../../contexts/auth/useAuth'
import { login } from '../../contexts/auth/AuthSlice'
import appIcon from '../../assets/appIcon.png'
import LoadingIndicator from '../loading/LoadingIndicator'
import styles from './auth.module.css'
import GoogleSignInButton from './GoogleSignInButton'

const LogInView = ({ onClose }) => {
  const [identifier, setIdentifier] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const { dispatch } = useAuth()
  const navigate = useNavigate()
  const identifierRef = useRef(null)

  const { executeRecaptcha } = useGoogleReCaptcha()

  useEffect(() => {
    if (identifierRef.current) {
      identifierRef.current.focus()
    }
  }, [])

  const handleLogin = async (event) => {
    event.preventDefault()
    setError('')
    setLoading(true)

    try {
      const recaptchaToken = await executeRecaptcha('login')

      const user = await login({ identifier, password, recaptchaToken })
      dispatch({ type: 'SET_USER', payload: user })
      navigate('/home')
    } catch (error) {
      console.error('Login Error:', error)
      setError(error.message || 'An error occurred during login.')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className={styles.authFormContainer}>
      <button className={styles.authCloseButton} onClick={onClose}>
        &times;
      </button>
      <img className={styles.logo} src={appIcon} alt="Paragliding logo" />
      <h1 className={styles.authFormTitle}>Log in to Vol</h1>
      <div className={styles.credentialsForm}>
        <form onSubmit={handleLogin}>
          {error && <div className={styles.signupError}>{error}</div>}
          <div className={styles.authFormHeader}>
            <h2>Enter your login credentials</h2>
          </div>
          <div className={styles.authInputGroup}>
            <input
              ref={identifierRef}
              type="text"
              placeholder="Your username or email"
              value={identifier}
              onChange={(e) => setIdentifier(e.target.value.toLowerCase())}
              autoComplete="username"
              required
              autoFocus
            />
          </div>
          <div className={styles.authInputGroup}>
            <input
              type="password"
              placeholder="Your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
              required
            />
          </div>
          <button
            type="submit"
            className={styles.createLoginButton}
            disabled={loading}
          >
            {loading ? <LoadingIndicator /> : 'Sign In'}
          </button>
        </form>
        <div className={styles.orSeparator}>or</div>
        <GoogleSignInButton
          className={styles.googleLoginButton}
          useOneTap={false}
        />
      </div>
    </div>
  )
}

export default LogInView
