import React, { useState, useEffect, useRef } from 'react'
import styled, { createGlobalStyle } from 'styled-components'

const ScrollLockStyles = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
  outline: none;
`

const Slider = styled.div.attrs((props) => ({
  style: {
    transform: `translateX(${props.translateX}px)`,
    transition: props.isDragging ? 'none' : 'transform 0.3s ease',
  },
}))`
  display: flex;
  align-items: center;
  width: 100%;
`

const ImageWrapper = styled.div`
  min-width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px;

  @media (max-width: 868px) {
    padding: 0;
  }
`

const Image = styled.img`
  max-width: calc(100% - 80px);
  max-height: 80vh;
  border-radius: 10px;

  @media (max-width: 868px) {
    max-width: 100%;
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  z-index: 1001;

  @media (max-width: 868px) {
    top: 60px;
  }
`

const ArrowButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: white;
  font-size: 3rem;
  cursor: pointer;
  z-index: 1001;
  padding: 10px;

  &:hover {
    color: #ccc;
  }
`

const LeftArrow = styled(ArrowButton)`
  left: 20px;
`

const RightArrow = styled(ArrowButton)`
  right: 20px;
`

export const Lightbox = ({
  images,
  currentIndex,
  onCloseRequest,
  onMovePrevRequest,
  onMoveNextRequest,
}) => {
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [currentTranslate, setCurrentTranslate] = useState(0)
  const [viewportWidth] = useState(window.innerWidth)
  const overlayRef = useRef(null)

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX)
    setIsDragging(true)
  }

  const handleTouchMove = (e) => {
    if (!isDragging) return
    const currentX = e.touches[0].clientX
    const diff = currentX - startX

    if (
      (currentIndex === 0 && diff > 0) ||
      (currentIndex === images.length - 1 && diff < 0)
    ) {
      return
    }

    setCurrentTranslate(diff)
  }

  const handleTouchEnd = () => {
    setIsDragging(false)
    const threshold = viewportWidth * 0.3

    if (currentTranslate > threshold && currentIndex > 0) {
      setCurrentTranslate(viewportWidth)
      setTimeout(() => {
        onMovePrevRequest()
      }, 300)
    } else if (
      currentTranslate < -threshold &&
      currentIndex < images.length - 1
    ) {
      setCurrentTranslate(-viewportWidth)
      setTimeout(() => {
        onMoveNextRequest()
      }, 300)
    } else {
      setCurrentTranslate(0)
    }
  }

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onCloseRequest()
      } else if (e.key === 'ArrowRight') {
        onMoveNextRequest()
      } else if (e.key === 'ArrowLeft') {
        onMovePrevRequest()
      }
    }

    const overlayElement = overlayRef.current
    overlayElement.addEventListener('keydown', handleKeyDown)

    return () => {
      overlayElement.removeEventListener('keydown', handleKeyDown)
    }
  }, [onCloseRequest, onMoveNextRequest, onMovePrevRequest])

  useEffect(() => {
    if (overlayRef.current) {
      overlayRef.current.focus()
    }
  }, [])

  useEffect(() => {
    setCurrentTranslate(0)
  }, [currentIndex])

  return (
    <>
      <Overlay ref={overlayRef} onClick={onCloseRequest} tabIndex={-1}>
        <CloseButton
          onClick={(e) => {
            e.stopPropagation()
            onCloseRequest()
          }}
        >
          &times;
        </CloseButton>
        <Slider
          translateX={currentTranslate - currentIndex * viewportWidth}
          isDragging={isDragging}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {images.map((src, index) => (
            <ImageWrapper key={index}>
              <Image src={src} alt={`Image ${index + 1}`} />
            </ImageWrapper>
          ))}
        </Slider>
        {currentIndex > 0 && (
          <LeftArrow
            onClick={(e) => {
              e.stopPropagation()
              onMovePrevRequest()
            }}
          >
            &#8249;
          </LeftArrow>
        )}
        {currentIndex < images.length - 1 && (
          <RightArrow
            onClick={(e) => {
              e.stopPropagation()
              onMoveNextRequest()
            }}
          >
            &#8250;
          </RightArrow>
        )}
      </Overlay>
      <ScrollLockStyles />
    </>
  )
}
