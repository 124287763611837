import React from 'react'
import styled from 'styled-components'

const FormField = styled.div`
  width: 100%;
`

const FormLabel = styled.label`
  color: var(--theme-color);
  font-size: 18px;
`

const Dropdown = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #555;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: black;
  color: ${(props) => (props.isDefault ? '#888' : '#fff')};
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;

  &:focus {
    outline: 2px solid var(--theme-color);
  }
`

const YearStarted = ({ value, onChange }) => {
  const currentYear = new Date().getFullYear()
  const years = Array.from(
    new Array(currentYear - 1969),
    (val, index) => 1970 + index
  )

  return (
    <FormField>
      <FormLabel htmlFor="yearStartedFlying">Year Started Flying</FormLabel>
      <Dropdown
        id="yearStartedFlying"
        value={value}
        onChange={onChange}
        isDefault={!value}
      >
        <option value="">Select Year</option>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Dropdown>
    </FormField>
  )
}

export default YearStarted
