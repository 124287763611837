import React, { useState } from 'react'
import { CommentList } from 'react-activity-feed'
import { LoadMoreComments } from './LoadMoreComments'
import CommentListItem from './CommentListItem'

export const CommentSection = ({ activityId, reactionCounts, objectId }) => {
  const [showComments, setShowComments] = useState(false)

  if (!reactionCounts?.comment) return null

  return (
    <>
      {!showComments && (
        <LoadMoreComments
          hasNextPage={true}
          loadNextPage={() => {
            setShowComments(true)
          }}
          refreshing={false}
        />
      )}

      {showComments && (
        <CommentList
          activityId={activityId}
          Paginator={LoadMoreComments}
          CommentItem={({ comment }) => (
            <CommentListItem
              key={comment.id}
              comment={comment}
              activityId={activityId}
              objectId={objectId}
            />
          )}
        />
      )}
    </>
  )
}
