import { useStreamContext } from 'react-activity-feed'
import useNotification from './useNotification'

export default function useMentionNotification() {
  const { client } = useStreamContext()
  const { createNotification } = useNotification()

  const createMentionNotification = async ({
    mentionedUserId,
    activity,
    text,
    postId,
    mentionType,
  }) => {
    if (!mentionedUserId || mentionedUserId === client.userId) {
      console.log(
        'No mentioned user ID provided or self-mention, skipping notification.'
      )
      return
    }

    try {
      await createNotification(
        mentionedUserId,
        'mention',
        {
          text,
          data: {
            ...client.currentUser.data,
            parentActivityId: activity.id,
            mentionType,
          },
        },
        `SO:post:${postId}`
      )

    } catch (error) {
      console.error('Failed to create mention notification:', error)
    }
  }

  return {
    createMentionNotification,
  }
}
