import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  padding: 20px;
`

const NotFoundTitle = styled.h1`
  font-size: 2.5rem;
  color: #333;
`

const NotFoundText = styled.p`
  font-size: 1.2rem;
  color: #666;
`

const BackButton = styled.button`
  background-color: #444;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;

  &:hover {
    background-color: #333;
  }
`

const NotFoundView = () => {
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <NotFoundContainer>
      <NotFoundTitle>404 Not Found</NotFoundTitle>
      <NotFoundText>
        The page you are looking for doesn't exist or another error occurred.
      </NotFoundText>
      <NotFoundText>
        Go back, or head over to the home page to choose a new direction.
      </NotFoundText>
      <BackButton onClick={handleGoBack}>Go Back</BackButton>
    </NotFoundContainer>
  )
}

export default NotFoundView
