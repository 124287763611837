import { Link } from 'react-router-dom'
import styled from 'styled-components'

import RelativeTime from '../date/RelativeTime'

const TextBlock = styled.div`
  display: flex;

  .user--name {
    color: white;
    font-weight: bold;
    white-space: nowrap;

    &:hover {
      text-decoration: underline;
    }
  }

  .user--id {
    margin-left: 5px;
    color: #777;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      text-decoration: underline;
    }
  }
`

export default function PostActorName({ time, name, username }) {
  return (
    <TextBlock>
      <Link to={`/${username}`} className="user--name">
        {name}
      </Link>
      <Link to={`/${username}`} className="user--id">
        @{username}
      </Link>
      <RelativeTime date={time} />
    </TextBlock>
  )
}
