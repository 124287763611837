import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useAuth } from '../contexts/auth/useAuth'
import { ParamUserProvider } from '../contexts/paramUser/ParamUserContext'
import StartView from '../components/auth/StartView'
import HomeContent from '../components/home/HomeContent'
import Layout from '../components/layout/Layout'
import NotificationContent from '../components/notifications/NotificationContent'
import ProfileContent from '../components/profile/ProfileContent'
import RightSide from '../components/rightSide/RightSide'
import Thread from '../components/thread/Thread'
import NotFoundView from '../components/notFound/NotFoundView'
import ResetPassword from '../components/auth/ResetPassword'

const ProtectedRoute = ({ isAuthenticated, children }) => {
  return isAuthenticated ? children : <Navigate to="/" replace />
}

export default function AppRoutes() {
  const { authState } = useAuth()

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<StartView />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/not-found" element={<NotFoundView />} />
      <Route path="*" element={<NotFoundView />} />

      {/* Protected Routes */}
      <Route
        path="/home"
        element={
          <ProtectedRoute isAuthenticated={authState.isAuthenticated}>
            <Layout>
              <HomeContent />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedRoute isAuthenticated={authState.isAuthenticated}>
            <Layout>
              <RightSide />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/:username"
        element={
          <ProtectedRoute isAuthenticated={authState.isAuthenticated}>
            <Layout>
              <ParamUserProvider>
                <ProfileContent />
              </ParamUserProvider>
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/:username/post/:id"
        element={
          <ProtectedRoute isAuthenticated={authState.isAuthenticated}>
            <Layout>
              <Thread />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/notifications"
        element={
          <ProtectedRoute isAuthenticated={authState.isAuthenticated}>
            <Layout>
              <NotificationContent />
            </Layout>
          </ProtectedRoute>
        }
      />
    </Routes>
  )
}
