import { useEffect } from 'react'

function useOutsideClick(refs, onClose) {
  useEffect(() => {
    function handleClickOutside(event) {
      let isOutside = true

      refs.forEach((ref) => {
        if (ref.current && ref.current.contains(event.target)) {
          isOutside = false
        }
      })

      if (isOutside) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [refs, onClose])
}

export default useOutsideClick
