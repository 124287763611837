import React from 'react'
import styled from 'styled-components'
import { useStreamContext, useFeedContext } from 'react-activity-feed'
import { useNavigate } from 'react-router-dom'
import FollowBtn from '../follow/FollowBtn'

const MenuContainer = styled.div`
  position: absolute;
  background-color: #222;
  border: 1px solid #333;
  border-radius: 5px;
  right: 10px;
  top: 40px;
  z-index: 10;
  width: 150px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  .menu-item {
    padding: 10px;
    cursor: pointer;
    color: white;
    text-align: center;
    border-bottom: 1px solid #333;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: #444;
    }
  }
`

const PostMoreMenu = React.forwardRef(
  ({ activity, onClose, isComment, isThread }, ref) => {
    const { client, user } = useStreamContext()
    const { refresh } = useFeedContext()
    const navigate = useNavigate()

    const handleDeleteActivity = async () => {
      try {
        const feed = client.feed('user', user.id)
        await feed.removeActivity(activity.id)
        refresh()
        onClose()
        if (isThread) navigate('/home')
      } catch (error) {
        console.error('Error deleting activity:', error)
      }
    }

    const handleDeleteComment = async () => {
      try {
        await client.reactions.delete(activity.id)
        refresh()
        onClose()
      } catch (error) {
        console.error('Error deleting comment:', error)
      }
    }

    const actorId = isComment ? activity.user.id : activity.actor.id

    return (
      <MenuContainer ref={ref}>
        {user.id !== actorId && (
          <div className="menu-item" onClick={onClose}>
            <FollowBtn userId={actorId} />
          </div>
        )}
        {user.id === actorId && (
          <div
            className="menu-item"
            onClick={isComment ? handleDeleteComment : handleDeleteActivity}
          >
            Delete
          </div>
        )}
      </MenuContainer>
    )
  }
)

export default PostMoreMenu
