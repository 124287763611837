import { createElement } from 'react'

const MAX_LINK_LENGTH = 150

export function formatStringWithLink(
  text,
  linkClass,
  noLink = false,
  handleMentionClick
) {
  const regex = /((https?:\/\/\S*)|(@\S*))/gi
  // const regex = /((https?:\/\/\S*)|(#\S*)|(@\S*))/gi // for hashtags
  const parts = []
  let lastIndex = 0

  let match
  while ((match = regex.exec(text)) !== null) {
    const { index } = match
    const currentMatch = match[0]

    // Add the text before the link or mention
    if (index > lastIndex) {
      const plainText = text.substring(lastIndex, index)
      const plainTextParts = plainText.split('\n')
      // eslint-disable-next-line no-loop-func
      plainTextParts.forEach((part, idx) => {
        if (idx > 0) {
          parts.push(createElement('br', { key: `br-${lastIndex}-${idx}` }))
        }
        parts.push(part)
      })
    }

    let url, label, onClick
    // if (match[0].startsWith('#')) {
    //   // it is a hashtag
    //   url = match[0]
    //   label = match[0]
    // } else
    if (currentMatch.startsWith('@')) {
      // it is a mention
      label = currentMatch
      url = `/${currentMatch.replace('@', '')}`
      onClick = () => handleMentionClick(currentMatch.replace('@', ''))
    } else {
      // it is a link
      url = currentMatch
      label = url.replace(/https?:\/\/|www\./, '')

      if (label.length > MAX_LINK_LENGTH) {
        label = `${label.slice(0, MAX_LINK_LENGTH)}...`
      }
    }

    const tag = noLink ? 'span' : 'a'

    parts.push(
      createElement(
        tag,
        {
          className: noLink ? '' : linkClass,
          href: !noLink && !onClick ? url : undefined,
          onClick: onClick || undefined,
          key: `link-${index}`,
          // style: onClick ? { color: 'blue', cursor: 'pointer' } : {},
        },
        label
      )
    )

    // Update lastIndex to the end of the current match
    lastIndex = index + currentMatch.length
  }

  // Add any remaining text after the last match
  if (lastIndex < text.length) {
    const remainingText = text.substring(lastIndex)
    const remainingTextParts = remainingText.split('\n')
    remainingTextParts.forEach((part, idx) => {
      if (idx > 0) {
        parts.push(createElement('br', { key: `br-${lastIndex + idx}` }))
      }
      parts.push(part)
    })
  }

  return parts
}
