import React, { useState } from 'react'
import { ReactionToggleIcon } from 'react-activity-feed'
import ThumbsUpIcon from '../Icons/ThumbsUpIcon'
import useLike from '../../hooks/useLike'

export const LikeButton = ({ activityId, objectId, reaction }) => {
  const { toggleCommentLike } = useLike()

  const [liked, setLiked] = useState(
    !!reaction.own_children?.comment_like?.length
  )
  const [likeCount, setLikeCount] = useState(
    reaction.children_counts?.comment_like || 0
  )
  const [pendingLike, setPendingLike] = useState(false)

  const handlePress = async () => {
    if (pendingLike) return

    const isCurrentlyLiked = liked
    const updatedLikeCount = isCurrentlyLiked ? likeCount - 1 : likeCount + 1

    setLiked(!liked)
    setLikeCount(updatedLikeCount)
    setPendingLike(true)

    try {
      await toggleCommentLike(reaction, activityId, objectId, isCurrentlyLiked)
    } catch (error) {
      setLiked(isCurrentlyLiked)
      setLikeCount(likeCount)
    } finally {
      setPendingLike(false)
    }
  }

  return (
    <ReactionToggleIcon
      counts={{ comment_like: likeCount }}
      own_reactions={liked ? { comment_like: [{}] } : {}}
      kind="comment_like"
      onPress={handlePress}
      activeIcon={<ThumbsUpIcon style={{ color: '#0ba8e0' }} />}
      inactiveIcon={<ThumbsUpIcon style={{ color: '#7a8287' }} />}
      labelSingle="like"
      labelPlural="likes"
    />
  )
}
