import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import styled from 'styled-components'

import UserPlaceholder from '../Icons/UserPlaceholder'

const Container = styled.div`
  display: flex;
  border-radius: 50%;
`

const StyledImage = styled.img`
  object-fit: cover;
  cursor: pointer;
  border-radius: 50%;
`

const UserImage = ({
  src,
  alt,
  username,
  userId,
  clickable = true,
  expandable = false,
}) => {
  const navigate = useNavigate()
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)
  const [key, setKey] = useState(0)
  const [error, setError] = useState(false)

  useEffect(() => {
    setTimeout(() => setKey(key + 1))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLightboxOpen])

  const handleClick = (e) => {
    e.preventDefault()
    if (clickable && username) {
      e.stopPropagation()
      navigate(`/${username}`)
    } else if (expandable) {
      setIsLightboxOpen(true)
    }
  }

  const handleImageError = (e) => {
    setError(true)
  }

  return (
    <Container>
      {src && !error ? (
        <StyledImage
          src={src}
          alt={alt}
          onClick={handleClick}
          onError={handleImageError}
        />
      ) : (
        <UserPlaceholder onClick={handleClick} />
      )}
      {isLightboxOpen && src && (
        <Lightbox
          key={src}
          mainSrc={src}
          onCloseRequest={() => setIsLightboxOpen(false)}
        />
      )}
    </Container>
  )
}

export default UserImage
