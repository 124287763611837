import { useFeedContext, useStreamContext } from 'react-activity-feed'
import useNotification from './useNotification'

export default function useLike() {
  const feed = useFeedContext()
  const { createNotification } = useNotification()
  const { client, user } = useStreamContext()

  const toggleLike = async (activity, hasLikedPost) => {
    const actor = activity.actor

    await feed.onToggleReaction('like', activity)

    if (!hasLikedPost && actor.id !== user.id) {
      createNotification(
        actor.id,
        'like',
        {
          data: {
            ...client.currentUser.data,
            parentActivityId: activity.id,
          },
        },
        `SO:post:${activity.object.id}`
      )
    }
  }

  const toggleCommentLike = async (reaction, activityId, objectId, hasLikedComment) => {
    const actor = reaction.user

    await feed.onToggleChildReaction('comment_like', reaction)

    if (!hasLikedComment && actor.id !== user.id) {
      createNotification(
        actor.id,
        'comment_like',
        {
          data: {
            ...client.currentUser.data,
            parentActivityId: activityId,
          },
        },
        `SO:post:${objectId}`
      )
    }
  }


  return { toggleLike, toggleCommentLike }
}
