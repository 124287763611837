import React from 'react'
import { FlatFeed, InfiniteScrollPaginator } from 'react-activity-feed'
import PostBlock from '../post/PostBlock'
import LoadingIndicator from '../loading/LoadingIndicator'

const FEED_ENRICH_OPTIONS = {
  withRecentReactions: true,
  withOwnReactions: true,
  withReactionCounts: true,
  withOwnChildren: true,
}

export default function GlobalFeed() {
  return (
    <FlatFeed
      feedGroup="allusers"
      userId="allusers"
      notify
      Activity={PostBlock}
      options={FEED_ENRICH_OPTIONS}
      Paginator={(props) => (
        <InfiniteScrollPaginator
          threshold={10}
          loadingIndicator={<LoadingIndicator />}
          {...props}
        />
      )}
    />
  )
}
