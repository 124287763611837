import React from 'react'
import { format } from 'date-fns'
import styled from 'styled-components'

const Time = styled.div`
  margin-left: 15px;
  color: #777;
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;

  &::after {
    content: '';
    width: 2px;
    height: 2px;
    background-color: #777;
    position: absolute;
    left: -8px;
    top: 0;
    bottom: 0;
    margin: auto 0;
  }
`

export default function RelativeTime({ date, style }) {
  const localDateNow = new Date()
  const utcDateNow = new Date(
    localDateNow.getTime() + localDateNow.getTimezoneOffset() * 60000
  )

  const utcDatePassed = new Date(date)
  const timeDiff = utcDateNow.getTime() - utcDatePassed.getTime()
  const minutesBetweenDates = Math.floor(timeDiff / (60 * 1000))
  const hoursBetweenDates = Math.floor(timeDiff / (60 * 60 * 1000))

  const lessThan24hrs = hoursBetweenDates < 24
  const lessThan1hr = minutesBetweenDates < 60

  const timeText = lessThan1hr
    ? `${minutesBetweenDates}m`
    : lessThan24hrs
    ? `${hoursBetweenDates}h`
    : format(utcDatePassed, 'MMM d')

  return <Time style={style}>{timeText}</Time>
}
