import React, { useState, useEffect, useRef } from 'react'
import { useFeedContext, useStreamContext } from 'react-activity-feed'
import styled from 'styled-components'
import LikeNotification from './LikeNotification'
import FollowNotification from './FollowNotification'
import CommentNotification from './CommentNotification'
import CommentLikeNotification from './CommentLikeNotification'
import MentionNotification from './MentionNotification'

const Container = styled.div`
  h1 {
    padding: 15px;
    font-size: 20px;
    color: white;
  }

  button {
    width: 100%;
  }

  .raf-avatar-group__avatar .raf-avatar--circle {
    border: 1px solid #333;
    margin-right: 2px;
    object-fit: cover;
  }
`

export default function NotificationContent() {
  const feed = useFeedContext()
  const [allNotifications, setAllNotifications] = useState([])
  const { client, user } = useStreamContext()
  const notificationContainerRef = useRef()

  useEffect(() => {
    async function fetchNotifications() {
      const notificationFeed = client.feed('notification', user.id)
      const response = await notificationFeed.get({
        limit: 50,
        mark_seen: true,
      })

      // Flatten all activities into individual items, but group them by object ID (post ID) and verb
      const groupedActivities = groupAndDeduplicateNotifications(
        response.results
      )

      // Sort groups by the most recent activity in each group
      const sortedActivities = groupedActivities.sort(
        (a, b) =>
          new Date(b.mostRecentActivity.time) -
          new Date(a.mostRecentActivity.time)
      )

      setAllNotifications(sortedActivities)
    }

    fetchNotifications()
  }, [client, user.id])

  useEffect(() => {
    if (!notificationContainerRef.current) return
    const anchorTags = notificationContainerRef.current.querySelectorAll('a')
    anchorTags.forEach((element) => {
      element.addEventListener('click', (e) => e.stopPropagation())
    })
    return () =>
      anchorTags.forEach((element) => {
        element.removeEventListener('click', (e) => e.stopPropagation())
      })
  }, [])

  useEffect(() => {
    const notifFeed = client.feed('notification', user.id)

    notifFeed.subscribe((data) => {
      if (data.new.length) {
        feed.refresh()
      }
    })

    return () => notifFeed.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Group notifications by activity type and parent ID, and deduplicate within each group
  const groupAndDeduplicateNotifications = (activityGroups) => {
    const groups = {}

    activityGroups.forEach((group) => {
      group.activities.forEach((activity) => {
        if (group.verb === 'follow') {
          const key = `${activity.id}`
          groups[key] = {
            activities: [activity],
            verb: group.verb,
            object: activity.object,
            mostRecentActivity: activity,
          }
        } else {
          const key = `${group.verb}_${activity.object.id}`

          if (!groups[key]) {
            groups[key] = {
              activities: [],
              verb: group.verb,
              object: activity.object,
              mostRecentActivity: activity,
            }
          }

          groups[key].activities.push(activity)

          // Keep track of the most recent activity for sorting
          if (
            new Date(activity.time) >
            new Date(groups[key].mostRecentActivity.time)
          ) {
            groups[key].mostRecentActivity = activity
          }
        }
      })
    })

    return Object.values(groups)
  }

  return (
    <Container ref={notificationContainerRef}>
      <h1>Notifications</h1>
      {allNotifications.map((group, index) => {
        const { verb, activities } = group

        if (verb === 'like') {
          return <LikeNotification key={index} likedActivities={activities} />
        }
        if (verb === 'comment') {
          return (
            <CommentNotification key={index} commentActivities={activities} />
          )
        }
        if (verb === 'follow') {
          return (
            <FollowNotification key={index} followActivities={activities} />
          )
        }
        if (verb === 'comment_like') {
          return (
            <CommentLikeNotification
              key={index}
              commentLikeActivities={activities}
            />
          )
        }
        if (verb === 'mention') {
          return (
            <MentionNotification key={index} mentionActivities={activities} />
          )
        }
        return null
      })}
    </Container>
  )
}
